import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import styles from "./Demo.module.scss"

const Demo = () => {
  useEffect(() => {
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        startAngle: -84,
        endAngle: 264,
        innerRadius: am5.percent(40)
      })
    );

    const cursor = chart.set(
      "cursor",
      am5radar.RadarCursor.new(root, {
        behavior: "zoomX"
      })
    );
    cursor.lineY.set("forceHidden", true);

    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
        exportable: false
      })
    );

    let xRenderer = am5radar.AxisRendererCircular.new(root, {
      minGridDistance: 30
    });

    xRenderer.grid.template.set("forceHidden", true);

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "category",
        renderer: xRenderer
      })
    );

    let yRenderer = am5radar.AxisRendererRadial.new(root, {});
    yRenderer.labels.template.set("centerX", am5.p50);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        min: 0,
        renderer: yRenderer
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(
      am5radar.RadarColumnSeries.new(root, {
        name: "Series 1",
        sequencedInterpolation: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category"
      })
    );
    // Rounded corners for columns
    series.columns.template.setAll({
      cornerRadius: 5,
      tooltipText: "{categoryX}: {valueY}"
    });

    // // Make each column to be of a different color
    series.columns.template.adapters.add("fill", function (
      fill: any,
      target: any
    ) {
      return chart?.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (
      stroke: any,
      target: any
    ) {
      return chart?.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    // // Set data
    let data = [];

    for (var i = 1; i < 21; i++) {
      data.push({ category: i, value: Math.round(Math.random() * 100) });
    }

    xAxis.data.setAll(data);
    series.data.setAll(data);

    // // Make stuff animate on load
    // // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, []);

  return <div className={styles.chartparent}>
  
  <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>;\
  </div>
};

export default Demo;
