export const getCategogryNameById = (catId: number): string => {
  let catName: string = "";
  if (catId === 1) {
    catName = "LQ";
  } else if (catId === 2) {
    catName = "CA";
  } else if (catId === 3) {
    catName = "MC";
  }
  return catName;
};

export const getSparkedTextByProfileId = (profileId: number) => {
  let sparkedText: string = "";
  if (profileId === 1 || profileId === 4) {
    sparkedText = "CYBERATTACK";
  } else if (profileId === 2 || profileId === 5) {
    sparkedText = "LIQUIDITY CRUNCH";
  } else if (profileId === 3 || profileId === 6) {
    sparkedText = "MILITARY CONFLICT";
  } else {
    sparkedText = "Equally Curious";
  }
  return sparkedText;
};
