import React from 'react';
import logo from './logo.svg';
import './App.scss';
import Rout from './Routes/Rout';

function App() {

  return (
    <div className="App">
      <meta name="apple-mobile-web-app-capable" content="yes" />
     <Rout/>
    </div>
  );
}

export default App;
