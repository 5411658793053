import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Loader.css";
import {useTranslation} from 'react-i18next'


const Loader = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  React.useEffect(() => {
    setTimeout(() => {
      navigate('/infograph')
    }, 5000);
  }, []);

  return (
    <div className="load_background">
      {/* <div id="floatingCirclesG">
        <div className="f_circleG" id="frotateG_01"></div>
        <div className="f_circleG" id="frotateG_02"></div>
        <div className="f_circleG" id="frotateG_03"></div>
        <div className="f_circleG" id="frotateG_04"></div>
        <div className="f_circleG" id="frotateG_05"></div>
        <div className="f_circleG" id="frotateG_06"></div>
        <div className="f_circleG" id="frotateG_07"></div>
        <div className="f_circleG" id="frotateG_08"></div>
      </div> */}
      <div className="loader_container">
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
        <div className="circle">
          <div></div>
        </div>
      </div>
        <Typography variant="h6" component="h6" className="load_txt" sx={{ fontFamily: 'inherit', fontSize: '1.5rem', letterSpacing: '1px' }}>
          {t('loader_page.calculationprogress')}
        </Typography>
    </div>
  );
};

export default Loader;
