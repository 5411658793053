import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import styles from "./MessageDialog.module.scss";
import ClearIcon from "@mui/icons-material/Clear";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

export interface MessageDialogProps {
  id?: string;
  keepMounted?: boolean;
  open: boolean;
  onClose: (event?: object, reason?: string) => void;
  message: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction="left"
      mountOnEnter
      unmountOnExit
      easing={{
        enter: "cubic-bezier(.17,.67,.83,.67)",
        exit: "cubic-bezier(.17,.67,.83,.67)",
      }}
      ref={ref}
      {...props}
    />
  );
});

function MessageDialog({ id, open, onClose, message }: MessageDialogProps) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={(event: object, reason: string) => onClose(event, reason)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles.message_dialog}
      disableEscapeKeyDown={true}
      PaperProps={
        (id != "info") ? { sx: {} } :
          ((id == "info" && window.innerWidth < 1025)
            ? {
              sx: {
                position: "fixed",
                bottom: "2%",
                right: 0,
                m: 0,
                width: "80vw",
              },
            }
            : {
              sx: {
                position: "fixed",
                bottom: "2%",
                right: "35%",
                m: 0,
                width: "25vw",
              }
            })
      }
    >
      <DialogContent>
        <DialogContentText
          className={styles.dialog}
          id="alert-dialog-description"
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          {id == "alert" && <>Click here</>}
          {id == "noFaceDetect" && (<>Close</>)}
          {id == "cameraPermission" && (<>Close</>)}
        </Button>
        {id == "info" && (
          <ClearIcon sx={{ padding: "5px" }} onClick={() => onClose()} />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default MessageDialog;
