import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import LanguageIcon from "@mui/icons-material/Language";
import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../Components/Logo/Logo";
import LogoTxt from "../../Assets/images/logoTxt.png";
import styles from "./Start.module.scss";
import { LanguageData } from "../../models/languageData";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import FaceNotFoundDialog from "../../Components/FaceNotFoundDialog/FaceNotFoundDialog";
import { buttonClick } from "../../Utils/GtagEvents";
const service_url  = process.env.REACT_APP_BASE_URL;

const Start = () => {
  const navigate = useNavigate();
  const [langauage, setLangauge] = React.useState('');
  const location = useLocation()
  const [faceDetectAlert, setFaceDetectAlert] = React.useState(false);
  const [lngData, setLngData] = React.useState<LanguageData[]>([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const socialCode = searchParams.get('socialCode');
  const {t} = useTranslation();
  let userLanguage:any = localStorage.getItem("i18nextLng");

  React.useEffect(() => {
    if (socialCode !== null) {
      pixelTracking(socialCode);
    }
  }, []);

  React.useEffect(() => {
    fetchLanguageApi();
  }, []);

  React.useEffect(() => {
    fetchLanguages();
  }, []);

  React.useEffect(() => {
    console.log(location.state)
    if (location.state && location.state.from) {
      setFaceDetectAlert(true)
    }
  },[])

  const pixelTracking = async (socialCode: string) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    };
    const result = await fetch(
      `${service_url}/api/pixels/${socialCode}`,
      requestOptions
    );
    const data = await result.json();
    console.log(data);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setLangauge(event.target.value);
    i18next.changeLanguage(event.target.value,(result)=>{
      console.log('22222',result);
    })
  };
  

  const clickStart = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    };
    const result = await fetch(`${service_url}/api/token`, requestOptions);
    const data = await result.json();
    console.log(data);
    if (data.statusCode === 200) {
      localStorage.setItem("token_id", data.data.token_id);
      navigate("/emotion-analysis");
    } else {
      console.log(data.statusCode + "-" + data.message);
    }
    buttonClick("GET-STARTED");
  };
  const fetchLanguageApi = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    };
    const result = await fetch(`${service_url}/api/languages/`, requestOptions);
    const data = await result.json();
    console.log("data value after fetching data.data",data.data);
    if (data.data.length > 0) {
     console.log("do",data.data)
     setLngData(data.data)
    }
  }

  // Fetch Language 
  const fetchLanguages = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    };
    const result = await fetch(`${service_url}/api/languages/`, requestOptions);
    const data = await result.json();
    if (data.data.length > 0) {
     setLngData(data.data)
    }
  };

  // Close Face Detection Alert / Camera Permission PopUp
  const handlePopUpClose = () => {
    setFaceDetectAlert(false)
  }

  return (
    <>
      <Box className={styles.start_Container}>
        <Box sx={{ flexGrow: 1 }} className={styles.startArea}>
          <Grid container className={styles.gc}>
            <div className={styles.logoO}>{/* <Logo /> */}</div>
            <img src={LogoTxt} alt="logo-PGIM" className={styles.newLogo} />
            <FormControl
              className={styles.lngDropdown}
              sx={{
                m: 1,
                minWidth: 70,
                position: "absolute",
                right: "0",
                margin: "4.5rem 1rem",
                display:"none"
              }}
              size="small"
            >
              {/* <InputLabel id="demo-select-small">
                <LanguageIcon />
              </InputLabel> */}
              <Select
                sx={{ backgroundColor: "#fff" }}
                labelId="demo-select-small"
                id="demo-select-small"
                value={userLanguage}
                label="Language"
                onChange={handleChange}
              >
                {
                  lngData.length>0 && lngData.map((lg,i)=>(
                    <MenuItem key={i} value={lg.shortname}>{lg.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <Grid className={styles.start} item xs={12}>


              <Typography variant="h6" component="h6" className={styles.startheading}>
                {t("start_page.heading1")}
              </Typography>
              <Typography variant="h6" component="h6" className={styles.startheading}>
              {t("start_page.heading2")}
              </Typography>


              <Typography variant="h6" component="h6" className={styles.starttextPara}>
              {t("start_page.subHeading1")}
              </Typography>
              <Typography variant="h6" component="h6" className={styles.starttextPara}>
              {t("start_page.subHeading2")}
              </Typography>
              <Box className={styles.buttondiv}>
                <Button variant="contained" sx={{ padding: 0 }} className={styles.button} onClick={() => clickStart()}>{t("start_page.buttonText")}</Button>
              </Box>
            </Grid>
            <FaceNotFoundDialog id="noFaceDetect" open={faceDetectAlert}  onClose={() => handlePopUpClose()} />
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Start;
