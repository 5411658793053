import React from 'react'
import ReactPlayer from 'react-player'
const service_url = `https://drqlpyno4dgscmoqfwcvqhcchm0pibce.lambda-url.us-east-1.on.aws`;

const VideoTest = () => {
    const [id,setId] = React.useState(1);
    const videoRef = React.useRef<any>()
    const [currentVideo,setCurrentVideo] = React.useState('http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4');
    React.useEffect(() => {
            fetchQuestionWithVideo(id);
    }, [id,currentVideo]);

    const fetchQuestionWithVideo = async (id: number) => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        };
        const result = await fetch(`${service_url}/api/question/${id}`, requestOptions);
        const data = await result.json();
        if (data.statusCode === 200) {
            console.log()
            setCurrentVideo(data.data[0].video_link);
        } else {
            // setErrMsg(data.statusCode + "-" + data.message)
        }
    }

    const getT = ()=>{
        console.log("get T",getCT())
    }

    const getCT = ()=>{
            // console.log(videoRef.current.getCurrentTime())
            return videoRef.current.getCurrentTime()
    }

  return (
    <div>
        <button onClick={getT}>getTime</button>
        <ReactPlayer ref={videoRef} onProgress={getCT} url={currentVideo} controls={true} muted={true} playing={true} width={"100vw"} height={"100vh"} onEnded={()=>setId(prevId => prevId + 1)}/>
    </div>
  )
}

export default VideoTest