import { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import styles from "./Demo.module.scss";
const Demo4 = () => {
  useEffect(() => {
    let root = am5.Root.new("chartdiv");
    root.setThemes([am5themes_Animated.new(root)]);
    // Generate and set data
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Setting_data
    let cat = -1;
    let value = 10;

    function generateData() {
      value = 60;
      cat++;
      return {
        category: "cat" + cat,
        value: value,
      };
    }

    function generateDatas(count: any) {
      cat = -1;
      let data = [];
      for (var i = 0; i < count; ++i) {
        data.push(generateData());
      }
      console.log("milind", data);

      return data;
    }

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        // wheelY: "zoomX",
        innerRadius: am5.p50,
        layout: root.verticalLayout,
      })
    );

    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
    let xRenderer = am5radar.AxisRendererCircular.new(root, {});
    xRenderer.labels.template.setAll({
      textType: "adjusted",
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "category",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5radar.AxisRendererRadial.new(root, {}),
      })
    );

    const colors = ["#adf500", "#00c400", "#00a100"];

    const objectColor: any = {
      cat0: ["#9FC150", "#9BBC4E", "#8BA944"],
      cat1: ["#4DA4D3", "#4899C6", "#4A9DCA"],
      cat2: ["#344557", "#496079", "#405369"],
    };

    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    for (var i = 0; i < 3; i++) {
      let series = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          stacked: true,
          name: "Series " + i,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          categoryXField: "category",
          fill: am5.color(colors[i]),
        })
      );
      // y axis line hidden
      yAxis.get("renderer").grid.template.setAll({
        strokeWidth: 0,
        visible: false,
      });
      //x axis line hidden
      // xAxis.get("renderer").grid.template.setAll({
      //   location: 0,
      //   strokeWidth: 0,
      //   visible: false,
      // });

      series.columns.template.setAll({
        width: am5.percent(99), //to remove the space between
        tooltipText: "{name}: {valueY}",
      });

      series.data.setAll(generateDatas(3));
      series.appear(1000);

      series.columns.template.adapters.add("fill", (fill, target: any) => {
        // console.log("target - ", target.dataItem)
        let index: any;
        index = Number(target.dataItem.component._settings.name.split(" ")[1]);
        const whatCat: any = target.dataItem.dataContext.category;

        // target.dataItem.component

        let getColors: any = objectColor[whatCat];

        let color = getColors[index];

        if (true) {
          return am5.color(color);
        } else {
          return fill;
        }
      });
    }

    // slider
    let slider = chart.children.push(
      am5.Slider.new(root, {
        orientation: "horizontal",
        start: 0.5,
        width: am5.percent(60),
        centerY: am5.p50,
        centerX: am5.p50,
        x: am5.p50,
      })
    );
    slider.events.on("rangechanged", function () {
      let start: any;
      start = slider.get("start");
      let startAngle = 270 - start * 179 - 1;
      let endAngle = 270 + start * 179 + 1;

      chart.setAll({ startAngle: startAngle, endAngle: endAngle });
      // yAxis.get("renderer").set("axisAngle", startAngle);
    });

    let data = generateDatas(3);
    xAxis.data.setAll(data);

    // Animate chart
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    chart.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, []);
  return (
    <div className="chartparent">
      <div className={styles.chartdiv} id="chartdiv"></div>
    </div>
  );
};

export default Demo4;
