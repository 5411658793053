import React, { useEffect } from 'react';
import About from '../About/About';
import FaceDetect from '../FaceDetect/FaceDetect';
import VideoEmotion from '../VideoEmotion/VideoEmotion';

const EmotionAnalysisContainer = () => {
    const [currentStage, setCurrentStage] = React.useState<number>(1);

    return (
        <>
            {currentStage == 1 && <About setCurrentStage={setCurrentStage} />}
            {currentStage == 2 && <FaceDetect setCurrentStage={setCurrentStage} />}
            {currentStage == 3 && <VideoEmotion />}
        </>
    )
}

export default EmotionAnalysisContainer