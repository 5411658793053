import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./Graph.module.scss";
// import Infograph from "./InfographReport";
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import html2canvas from "html2canvas";
import LogoTxt from "../../Assets/images/logoTxt.png";
import reloadSvg from "../../Assets/images/reload.svg";
import useBreakpoint from "../../hooks/useBreakPoint";
import useLongPress from "./useLongPress";
// import * as htmlToImage from "html-to-image";
import infographResp from './infograph.content.json';
import { InfographContent } from "./infograph.model";
import PGIM_QR from "../../Assets/images/QRs/PGIM_Landing.png";
import InfoIcon from "../../Assets/images/infoIcon.svg";
import MessageDialog from "../../Components/MessageDialog/MessageDialog";
import {graphInfo} from "./graphInfo";
import { useTranslation } from "react-i18next";
import { Languages } from "../../models/languages.enum";

const Graph = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [infographContent, setInfographContent] = useState<InfographContent>();
  const [result, setResult] = useState<any>();
  const [show, setShow] = useState(false);
  const [alertMessageOpen, setAlertMessageOpen] = useState(false);
  const [infoMessageOpen, setInfoMessageOpen] = useState(false);
  const service_url = process.env.REACT_APP_BASE_URL;
  let [searchParams, setSearchParams] = useSearchParams();
  const ipad = searchParams.get('ipad');
  const tokenForIpad = searchParams.get('token_id');
  const {t} = useTranslation();
  let selectedlangauage = localStorage.getItem("i18nextLng") || Languages.English; 
  
  // const ref: any = useRef();
  const ref = useRef<HTMLDivElement>(null);
  const breakpint = useBreakpoint();
  // useEffect(() => {
  //   setTimeout(() => {
  //     setShow(true);
  //   }, 2000);
  // }, []);

  const fetchinfographdata = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    var fromattedData = [];
    let token_id = localStorage.getItem('token_id');
    if (token_id == null && ipad == null) {
      console.log('token not found!--');
      setAlertMessageOpen(true);
    }
    else {
      token_id = (ipad == "1" && tokenForIpad !=null) ? (token_id = tokenForIpad ): token_id;
      console.log(token_id)
      const result = await fetch(`${service_url}/api/scores/getReport?token_id=${token_id}`, requestOptions);
      const data = await result.json();
      console.log("graphapi====", data);
      if (data.statusCode === 200) {
        setInfographContent(infographResp.find((x: InfographContent) => x.profileId == data.data.profileId && x.i18nextLng==selectedlangauage && x.scenario==data.data.scenario));
      }
      // var randomData = data[Math.floor(Math.random() * data.length)];
      // console.log("randomData", randomData);
      fromattedData[0] = data.data.likelyhood1;
      fromattedData[1] = data.data.likelyhood2;
      fromattedData[2] = data.data.likelyhood3;
      fromattedData[3] = data.data.impact1;
      fromattedData[4] = data.data.impact2;
      fromattedData[5] = data.data.impact3;
      fromattedData[6] = data.data.prepardness1;
      fromattedData[7] = data.data.prepardness2;
      fromattedData[8] = data.data.prepardness3;
      // console.log("formattedData==", fromattedData);D
      // localStorage.setItem("result", randomData["result"]);
    }
    return fromattedData;
  };

  //graph logic begin
  useEffect(() => {
    (async () => {
      var formaatedDataFromApi: any = await fetchinfographdata();
      let customfont = 8;

      if (breakpint === "md") {
        customfont = 3;
      }
      if (breakpint === "xs") {
        customfont = 10;
      }
      let root = am5.Root.new("chartdiv");
      root.setThemes([am5themes_Animated.new(root)]);
      // Generate and set data
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Setting_data
      let cat = -1;
      let catvalue = -1;
      // function
      //[1,2,3,4,5,6,7,8,9]
      // let value = [8.34,13.34,16.67,33.34,33.34,33.34,59.34,53.34,50];

      console.log("fetchinfographdata========", formaatedDataFromApi);
      const value: any = formaatedDataFromApi;
      // const value = [6, 9, 9, 9, 11, 22, 17, 18, 33];
      const maxValue = Math.max((value[0] + value[3] + value[6]), (value[1] + value[4] + value[7]), (value[2] + value[5] + value[8]));
      const newArr: any = value.map((x: any, i: any) => {
        // if (i === 0 || i === 3 || i === 6) {
        //   return (x / (value[0] + value[3] + value[6])) * maxValue;
        // } else if (i === 1 || i === 4 || i === 7) {
        //   return (x / (value[1] + value[4] + value[7])) * maxValue;
        // } else if (i === 2 || i === 5 || i === 8) {
        //   return (x / (value[2] + value[5] + value[8])) * maxValue;
        // }
        return x;
      });
      // console.log("mmm", newArr);

      function getTextRotation(i: number) {
        if (i == 1 || i == 4 || i == 7) {
          // console.log(i);
          return -60;
        } else if (i == 2 || i == 5 || i == 8) {
          return 0;
        }
        if (i == 3 || i == 6 || i == 9) {
          return 60;
        }
      }
      function generateData() {
        cat++;
        catvalue++;
        let categories = [
          "LIQUIDITY CRUNCH",
          "CYBERATTACK",
          "MILITARY CONFLICT",
        ];
        return {
          category: categories[cat],
          value: newArr[catvalue],
        };
      }

      function generateDatas(count: any) {
        cat = -1;

        let data = [];
        for (var i = 0; i < count; ++i) {
          data.push(generateData());
        }

        return data;
      }

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/radar-chart/
      let chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          // wheelY: "zoomX",
          innerRadius: am5.p50,
          layout: root.verticalLayout,
        })
      );

      // Create axes and their renderers
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
      let xRenderer = am5radar.AxisRendererCircular.new(root, {});
      // console.log("mmmmmmmm",xRenderer);
      // xRenderer.labels.removeValue("LIQUIDITY CRUNCH")
      xRenderer.labels.template.setAll({
        // textType: "adjusted",
        textType: "circular",
        fill: am5.color("#fff"),
        fontSize: 10,
        radius:-1      });

      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: "category",
          renderer: xRenderer,
          // rotation:10,
          width: 1000,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5radar.AxisRendererRadial.new(root, {}),
        })
      );

      const colors = ["#adf500", "#00c400", "#00a100"];

      const objectColor: any = {
        "LIQUIDITY CRUNCH": ["#D852FE", "#03CDFB", "#4D81FF"],
        "CYBERATTACK": ["#D852FE", "#03CDFB", "#4D81FF"],
        "MILITARY CONFLICT": ["#D852FE", "#03CDFB", "#4D81FF"],
      };

      // Create series
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
      catvalue = -1;

      let subCatNames = ["LIKELIHOOD", "IMPACT", "PREPAREDNESS"];
      for (var i = 0; i < 3; i++) {
        let series = chart.series.push(
          am5radar.RadarColumnSeries.new(root, {
            stacked: true,
            name: `${subCatNames[i]} ${formaatedDataFromApi[i]} `,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            categoryXField: "category",
            fill: am5.color(colors[i]),
          })
        );
        yAxis.get("renderer").grid.template.setAll({
          strokeWidth: 0,
          visible: false,
        });

        xAxis.get("renderer").grid.template.setAll({
          location: 0,
          strokeWidth: 0,
          visible: false,
        });

        series.bullets.push(function () {
          // console.log("****", Math.floor(i));
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              // text:`${subCatNames[Math.floor(i / 3)]} ${formaatedDataFromApi[i++]}%`,
              // text: `${formaatedDataFromApi[i++]}`,
              // fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              fontSize: customfont,
              // width : am5.p100,
              fontWeight: "400",
              textAlign: "center",
              fill: am5.color("#fff"),
              rotation: getTextRotation(i),
            }),
          });
        });
        // columnTemplate.width = am5.percent(100)

        series.columns.template.setAll({
          width: am5.percent(98), //to remove the space between
          tooltipText: "{name}: {valueY}",
        });

        series.data.setAll(generateDatas(3));

        series.appear(1000, 100);
        // console.log("klkkkkkkkkk",series)

        series.columns.template.adapters.add("fill", (fill, target: any) => {
          let index: any;
          let tar: any = target.dataItem.component._settings.name;
          index = subCatNames.indexOf(tar.substr(0, tar.indexOf(" ")));
          const whatCat: any = target.dataItem.dataContext.category;
          // console.log("Check", whatCat)

          let getColors: any = objectColor[whatCat];
          let color = getColors[index];

          if (true) {
            return am5.color(color);
          } else {
            return fill;
          }
        });
      }
      i = 0;

      // slider
      let slider = chart.children.push(
        am5.Slider.new(root, {
          orientation: "horizontal",
          start: 0.5,
          width: am5.percent(60),
          centerY: am5.p50,
          centerX: am5.p50,
          x: am5.p50,
        })
      );
      slider.events.on("rangechanged", function () {
        let start: any;
        start = slider.get("start");
        let startAngle = 270 - start * 179 - 1;
        let endAngle = 270 + start * 179 + 1;

        chart.setAll({ startAngle: startAngle, endAngle: endAngle });
        // yAxis.get("renderer").set("axisAngle", startAngle);
      });
      const mydata = [
        {
          category: "LIQUIDITY CRUNCH",
          value: 10,
        },
        { category: "CYBERATTACK", value: 6 },
        { category: "MILITARY CONFLICT", value: 9 },
      ];
      // xAxis.data.set("fill", am5.color(0xff0000));
      xAxis.data.setAll(mydata);
      // xAxis.labelsContainer.hide()// hides label of the chart

      // label.set("text", "[#888]This is gray[/]. [bold]And this is bold[/]!");
      // xAxis.get("renderer").labels.template.setAll({
        // text : mydata"[#888]This is gray[/].\n [bold]And this is bold[/]!",
        // fill: am5.color("#fff"),
        // fontSize: "12px"

        // width: am5.percent(10),
      // });

      // Animate chart
      // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
      yAxis.hide();

      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    })();
  }, [breakpint]);

  //graph logic end

  const handleclicked = () => {
    localStorage.removeItem("token_id");
    window.open("https://www.pgim.com/global-tail-risks", "_self");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
      var local: any = localStorage.getItem("result")?.toString();
      setResult(local);
    }, 1000);
    return () => clearTimeout(timer);
  }, [result]);

  //to download the graph
  const handleclick = () => {
    var canvas = document.getElementById("downloaddiv");
    PrintDiv(canvas);
  };
  const longPress = useLongPress(handleclick, 2000);

  function PrintDiv(div: any) {
    html2canvas(div).then((canvas) => {
      var myImage = canvas?.toDataURL();
      downloadURI(myImage, "infograph.png");
    });
  }

  function downloadURI(uri: any, name: any) {
    var link = document.createElement("a");

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    //after creating link you should delete dynamic link
    //clearDynamicLink(link);
  }

  // Close Alert Message PopUp and Disabling Outside click
  // -----------------------------------------------------------
  // Source1- https://smartdevpreneur.com/how-to-disable-mui-dialog-backdrop-click/
  // Source2- https://mui.com/material-ui/api/dialog/
  const handlePopUpClose = (event: {},
    reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setAlertMessageOpen(false);
      navigate("/")
    }
  }

  // Open Info PopUp
  const handleInfoPopUpOpen = ()=>{
    setInfoMessageOpen(true)
  }

  // Close Info PopUp
  const handleInfoPopUpClose = ()=>{
    setInfoMessageOpen(false)
  }

  return (
    <>
      <Box {...longPress} className={styles.graph_container} id="downloaddiv">
        <div className={styles.graph}>
          <div className={styles.headerline}>
            <img src={LogoTxt} alt="logo-PGIM" className={styles.newLogo} />
            <img src={reloadSvg} onClick={() => navigate('/')} alt="logo-PGIM" className={styles.newSvg} />
          </div>
          <div className={styles.demo_graph}>
            <div className={styles.chartparent}>
              <div className={styles.chartdiv} id="chartdiv"></div>
              <div className={styles.emotionmeter}> <p>YOUR</p> <p>EMOTION METER</p> </div>
              <div className={styles.legendLinaers}>
              <div className={styles.legendLinaer}>
                  <div className={styles.legendLinearBefore} style={{ backgroundColor: '#4D81FF' }}></div>
                  <div className={styles.legendLinaerText}>{t("infograph_page.event")}</div>
                </div>
                <div className={styles.legendLinaer}>
                  <div className={styles.legendLinearBefore} style={{ backgroundColor: '#03CDFB' }}></div>
                  <div className={styles.legendLinaerText}>{t("infograph_page.likelihood")}</div>
                </div>
                <div className={styles.legendLinaer}>
                  <div className={styles.legendLinearBefore} style={{ backgroundColor: '#D852FE' }}></div>
                  <div className={styles.legendLinaerText}>{t("infograph_page.impact")}</div>
                </div>
              </div>
              {/* <ul className={styles.legendList}>
          <li><div className={styles.legendListStyleBefore} style={{backgroundColor:'#4D81FF'}}></div> <div className={styles.legendListText}>LIKELIHOOD</div></li>
          <li><div className={styles.legendListStyleBefore} style={{backgroundColor:'#03CDFB'}} ></div> <div className={styles.legendListText}>IMPACT</div></li>
          <li><div className={styles.legendListStyleBefore} style={{backgroundColor:'#D852FE'}} ></div> <div className={styles.legendListText}>PREPAREDNESS</div></li>
        </ul> */}
            </div>            </div>
          <Box className={styles.buttondiv}>
            <div>
              {/* <Typography
                variant="h6"
                component="h6"
                className={styles.graph_result}
              >
                YOUR RESULTS SHOW:
              </Typography> */}
              {/* <Typography className={styles.graph_result_next} sx={{ fontWeight: '100' }}>
                {infographContent?.title}
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                className={styles.graph_textPara}
              >
                {infographContent?.subtitle1}
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                className={styles.graph_textPara}
              >
                {infographContent?.subtitle2}
              </Typography> */}
            </div>

            <div className={styles.headertextdiv}>
            <Typography
              variant="h6"
              component="h6"
              className={styles.heading}
            >
             {t("infograph_page.heading")}
            </Typography>
          </div>

            <div className={styles.reportTextContainer}>
              <div className={styles.reportTextTop}>
                <div>
                {infographContent?.title}
                {/* Your biometric results indicate that the LIQUIDITY CRUNCH activated the strongest reaction */}
                </div>
                <span className={styles.seprator}></span>
              </div>
              <div className={styles.reportTextBottom}>
              <div>
              {/* Your stated results indicate you would like to prepare for the impact of this event. */}
              {infographContent?.subtitle1}
                <hr></hr>
              <p>See the complete survey responses and how to mitigate tail risks.</p>
              </div>
              </div>
            </div>  

            {!ipad && (<Button
              variant="contained"
              className={styles.button}
              onClick={handleclicked}
            >
              {t("infograph_page.buttonText")}
            </Button>)}
            {!ipad && (<div className={styles.infoImageContainer} onClick={handleInfoPopUpOpen}>
              <img src={InfoIcon} alt="graph_info" />
            </div>)}
            {
              ipad && (<div className={styles.qrImageContainer}>
                <img src={PGIM_QR} alt="PGIM QR" />
              </div>)
            }
            <Typography
              variant="h6"
              component="h6"
              className={styles.graph_disclaimer}
            >
              {t("infograph_page.text1")}
            </Typography>
          </Box>
        </div>
      </Box>
      <MessageDialog id="alert" open={alertMessageOpen} message={'Please try our Tail Risk Biometric study!'} onClose={(event: any, reason: any) => handlePopUpClose(event, reason)} />
      <MessageDialog id="info" open={infoMessageOpen} message={graphInfo} onClose={() => handleInfoPopUpClose()} />
    </>
  );
};

export default Graph;