import { useEffect, useState, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import styles from "./Demo.module.scss";
import useBreakpoint from "../../hooks/useBreakPoint";
import useLongPress from "./useLongPress";
import html2canvas from "html2canvas";

const Infograph = () => {
  const service_url = `https://drqlpyno4dgscmoqfwcvqhcchm0pibce.lambda-url.us-east-1.on.aws`;

  const ref: any = useRef();
  const breakpint = useBreakpoint();
  // console.log("breakpint", breakpint);
  //fetching the data
  const fetchinfographdata = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const result = await fetch(`${service_url}/api/scores`, requestOptions);
    const data = await result.json();
    console.log("graphapi====", data);
    if (data.statusCode === 200) {
    }
    var randomData = data[Math.floor(Math.random() * data.length)];
    console.log("randomData", randomData);
    var fromattedData = [];
    fromattedData[0] = randomData.likelyhood1;
    fromattedData[1] = randomData.likelyhood2;
    fromattedData[2] = randomData.likelyhood3;
    fromattedData[3] = randomData.impact1;
    fromattedData[4] = randomData.impact2;
    fromattedData[5] = randomData.impact3;
    fromattedData[6] = randomData.prepardness1;
    fromattedData[7] = randomData.prepardness2;
    fromattedData[8] = randomData.prepardness3;
    console.log("formattedData==", fromattedData);
    localStorage.setItem("result", randomData["result"]);
    return fromattedData;
  };

  useEffect(() => {
    (async () => {
      var formaatedDataFromApi = await fetchinfographdata();

      let customfont = 8;

      if (breakpint === "md") {
        customfont = 3;
      }
      if (breakpint === "xs") {
        customfont = 5;
      }
      let root = am5.Root.new("chartdiv");
      root.setThemes([am5themes_Animated.new(root)]);
      // Generate and set data
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Setting_data
      let cat = -1;
      let catvalue = -1;
      // function
      //[1,2,3,4,5,6,7,8,9]
      // let value = [8.34,13.34,16.67,33.34,33.34,33.34,59.34,53.34,50];

      console.log("fetchinfographdata========", formaatedDataFromApi);
      const value: any = formaatedDataFromApi;
      // const value = [6, 9, 9, 9, 11, 22, 17, 18, 33];
      const newArr: any = value.map((x: any, i: any) => {
        if (i === 0 || i === 3 || i === 6) {
          return (x / (value[0] + value[3] + value[6])) * 299;
        } else if (i === 1 || i === 4 || i === 7) {
          return (x / (value[1] + value[4] + value[7])) * 299;
        } else if (i === 2 || i === 5 || i === 8) {
          return (x / (value[2] + value[5] + value[8])) * 299;
        }
      });
      // console.log("mmm", newArr);

      function getTextRotation(i: number) {
        if (i == 1 || i == 4 || i == 7) {
          // console.log(i);
          return -60;
        } else if (i == 2 || i == 5 || i == 8) {
          return 0;
        }
        if (i == 3 || i == 6 || i == 9) {
          return 60;
        }
      }
      function generateData() {
        cat++;
        catvalue++;
        let categories = [
          "LIQUIDITY CRUNCH",
          "MILITARY CONFLICT",
          "CYBERATTACK",
        ];
        return {
          category: categories[cat],
          value: newArr[catvalue],
        };
      }

      function generateDatas(count: any) {
        cat = -1;

        let data = [];
        for (var i = 0; i < count; ++i) {
          data.push(generateData());
        }

        return data;
      }

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/radar-chart/
      let chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          // wheelY: "zoomX",
          innerRadius: am5.p50,
          layout: root.verticalLayout,
        })
      );

      // Create axes and their renderers
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
      let xRenderer = am5radar.AxisRendererCircular.new(root, {});
      // console.log("mmmmmmmm",xRenderer);
      // xRenderer.labels.removeValue("LIQUIDITY CRUNCH")
      // xRenderer.labels.template.setAll({
      //   // textType: "adjusted",
      //   textType: "circular",
      // });

      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: "category",
          renderer: xRenderer,
          // rotation:10,
          width: 1000,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5radar.AxisRendererRadial.new(root, {}),
        })
      );

      const colors = ["#adf500", "#00c400", "#00a100"];

      const objectColor: any = {
        "LIQUIDITY CRUNCH": ["#D852FE", "#03CDFB", "#4D81FF"],
        "MILITARY CONFLICT": ["#D852FE", "#03CDFB", "#4D81FF"],
        CYBERATTACK: ["#D852FE", "#03CDFB", "#4D81FF"],
      };

      // Create series
      // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
      catvalue = -1;

      let subCatNames = ["LIKELIHOOD", "IMPACT", "PREPAREDNESS"];
      for (var i = 0; i < 3; i++) {
        let series = chart.series.push(
          am5radar.RadarColumnSeries.new(root, {
            stacked: true,
            name: `${subCatNames[i]} ${formaatedDataFromApi[i]} `,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            categoryXField: "category",
            fill: am5.color(colors[i]),
          })
        );
        yAxis.get("renderer").grid.template.setAll({
          strokeWidth: 0,
          visible: false,
        });

        xAxis.get("renderer").grid.template.setAll({
          location: 0,
          strokeWidth: 0,
          visible: false,
        });

        series.bullets.push(function () {
          // console.log("************", Math.floor(i));
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              // text:`${subCatNames[Math.floor(i / 3)]} ${formaatedDataFromApi[i++]}%`,
              text: `${formaatedDataFromApi[i++]}`,
              // fill: root.interfaceColors.get("alternativeText"),
              centerY: am5.p50,
              centerX: am5.p50,
              fontSize: customfont,
              // width : am5.p100,
              fontWeight: "400",
              textAlign: "center",
              fill: am5.color("#fff"),
              rotation: getTextRotation(i),
            }),
          });
        });
        // columnTemplate.width = am5.percent(100)

        series.columns.template.setAll({
          width: am5.percent(98), //to remove the space between
          tooltipText: "{name}: {valueY}",
        });

        series.data.setAll(generateDatas(3));

        series.appear(1000, 100);
        // console.log("klkkkkkkkkk",series)

        series.columns.template.adapters.add("fill", (fill, target: any) => {
          let index: any;
          let tar: any = target.dataItem.component._settings.name;
          index = subCatNames.indexOf(tar.substr(0, tar.indexOf(" ")));
          const whatCat: any = target.dataItem.dataContext.category;
          // console.log("Check", whatCat)

          let getColors: any = objectColor[whatCat];
          let color = getColors[index];

          if (true) {
            return am5.color(color);
          } else {
            return fill;
          }
        });
      }
      i = 0;

      // slider
      let slider = chart.children.push(
        am5.Slider.new(root, {
          orientation: "horizontal",
          start: 0.5,
          width: am5.percent(60),
          centerY: am5.p50,
          centerX: am5.p50,
          x: am5.p50,
        })
      );
      slider.events.on("rangechanged", function () {
        let start: any;
        start = slider.get("start");
        let startAngle = 270 - start * 179 - 1;
        let endAngle = 270 + start * 179 + 1;

        chart.setAll({ startAngle: startAngle, endAngle: endAngle });
        // yAxis.get("renderer").set("axisAngle", startAngle);
      });
      const mydata = [
        {
          category: "LIQUIDITY CRUNCH",
          value: 10,
        },
        { category: "MILITARY CONFLICT", value: 9 },
        { category: "CYBERATTACK", value: 6 },
      ];
      // xAxis.data.set("fill", am5.color(0xff0000));
      xAxis.data.setAll(mydata);
      // xAxis.labelsContainer.hide()// hides label of the chart

      // label.set("text", "[#888]This is gray[/]. [bold]And this is bold[/]!");
      xAxis.get("renderer").labels.template.setAll({
        // text : mydata"[#888]This is gray[/].\n [bold]And this is bold[/]!",
        fill: am5.color("#fff"),

        // width: am5.percent(10),
      });

      // Animate chart
      // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
      yAxis.hide();

      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    })();
  }, [breakpint]);

  //to download the graph
  const handleclick = () => {
    var canvas = document.getElementById("chartdiv");
    PrintDiv(canvas);
  };
  const longPress = useLongPress(handleclick, 2000);

  function PrintDiv(div: any) {
    html2canvas(div).then((canvas) => {
      var myImage = canvas?.toDataURL();
      downloadURI(myImage, "infograph.png");
    });
  }

  function downloadURI(uri: any, name: any) {
    var link = document.createElement("a");

    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    //after creating link you should delete dynamic link
    //clearDynamicLink(link);
  }

  return (
    <>
      <div className={styles.chartparent}>
        <div {...longPress} className={styles.chartdiv} id="chartdiv"></div>
      </div>
    </>
  );
};

export default Infograph;
