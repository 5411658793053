import BGid1 from "../../Assets/images/id11.jpg";
import BGid2 from "../../Assets/images/id21.jpg";
import BGid3 from "../../Assets/images/id31.jpg";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import * as faceapi from "face-api.js";
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import styles from "./VideoEmotion.module.scss";
import ProgressBar from '@ramonak/react-progress-bar';
import { Languages } from "../../models/languages.enum";
import { answerClick, buttonClick } from "../../Utils/GtagEvents";
import { getCategogryNameById, getSparkedTextByProfileId } from "../../Utils/utils";

const enSubtitle = require("../../Assets/subtitles/sintel_en.vtt")
const service_url  = process.env.REACT_APP_BASE_URL;
const socketUrl:any = process.env.REACT_APP_BASE_SOCKET_URL;

const VideoEmotion = () => {
    let selectedlangauage = localStorage.getItem("i18nextLng") || Languages.English; 
    const naviagte = useNavigate();
    const videoRef = React.useRef<any>();
    const [currentVideo, setCurrentVideo] = React.useState('');
    // const [playClicked, setPlayClicked] = React.useState(false);
    // const [showUserConsent, setShowUserConsent] = React.useState(false);
    const [questionId, setQuestionId] = React.useState(1);
    const [showGoToTailReport, setShowGoToTailReport] = React.useState<boolean>(false);
    const [backgroudImage, setBackgroudImage] = React.useState<any>();
    let isVideoLoaded = false;
    const [isVideoLoadedState, setIsVideoLoadedState] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState<any>(null);
    const [QnASession, setQnASession] = React.useState(false);
    const [catName, SetCatName] = React.useState('');
    const [QnAData, setQnAData] = React.useState({ id: questionId, question: '', option1: '', option2: '', option3: '', option4: '' });
    const [answerApiBody, setAnswerApiBody] = React.useState({
        "token": localStorage.getItem('token_id'),
        "question_id1": "1",
        "answer1": "",
        "question_id2": "2",
        "answer2": "",
        "question_id3": "3",
        "answer3": ""
    });
    const [timerIntervalId, setTimerIntervalId] = React.useState<any>();
    var answer3: any;
    const [faceDetectionCount, setfaceDetectionCount] = React.useState<number>(0)
    let shoudSendExpressionData = false;
    const { sendJsonMessage, lastJsonMessage, getWebSocket, readyState } = useWebSocket(socketUrl, { share: true, shouldReconnect: () => false });

    // Progress Bar
    const [completed1, setComplted1] = React.useState<string | number>(1);
    const [completed2, setComplted2] = React.useState<string | number>(1);
    const [completed3, setComplted3] = React.useState<string | number>(1);

    // face-api.js
    const videoFaceApiRef = React.useRef<any>();
    const canvasRef = React.useRef<any>();

    const startFaceApi = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((currentStream) => {
                videoFaceApiRef.current.srcObject = currentStream;
                if (!isVideoLoaded && !isVideoLoadedState) {
                    // setShowUserConsent(true);
                    isVideoLoaded = true;
                    setIsVideoLoadedState(true);
                    detectFace(videoFaceApiRef.current)
                }
            })
            .catch((err) => {
                console.error(err)
            });
    }
    const setQnaBackground = () => {
        let questionId = localStorage.getItem('questionId');
        if (questionId !== null) {
            if (questionId == '1') {
                setBackgroudImage(`url(${BGid1})`);
            }
            else if (questionId == '2') {
                setBackgroudImage(`url(${BGid2})`);
            }
            else if (questionId == '3') {
                setBackgroudImage(`url(${BGid3})`);
            }
        }
    }
    const detectFace = (video: any) => {
        socketConncectionConfigure()
        video.addEventListener('play', () => {
            setTimeout(() => {
                setShowGoToTailReport(false);
            }, 4000);
            const displaySize = { width: video.width, height: video.height };
            // faceapi.matchDimensions(canvasRef.current, displaySize);
            if (timerIntervalId) {
                clearInterval(timerIntervalId);
            }
            setTimerIntervalId(setInterval(async () => {
                const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
                    .withFaceLandmarks().withFaceExpressions();
                // sendJsonMessage({"action":"pushExpressionData","data":{...expressions,"token_id":localStorage.getItem("token_id"),id}})
                // if (lastJsonMessage) {
                //     console.log(lastJsonMessage)
                // }
                // console.log(readyState)
                // getWebSocket()?.close()
                // console.log(detections);
                if (detections && detections.length > 0) {
                    var expressions = detections[0]['expressions'];
                    if (shoudSendExpressionData) {
                        sendJsonMessage({ "action": "pushExpressionData", "data": { ...expressions, "token_id": localStorage.getItem("token_id"), id: localStorage.getItem("questionId") } });
                        shoudSendExpressionData = false;
                    }
                    setfaceDetectionCount(0)
                } else {
                    setfaceDetectionCount((faceDetectionCount) => faceDetectionCount + 1);

                }
            }, 1000));
        })
    }

    const loadModels = () => {
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
            faceapi.nets.faceExpressionNet.loadFromUri('/models'),
        ]).then(startFaceApi)
    }

    // Scoket 

    const socketConncectionConfigure = () => {
        videoRef.current.addEventListener('timeupdate', (e: any) => {
            // console.log(Math.floor(e.target.currentTime%5)==0);
            if (Math.floor(e.target.currentTime % 9) == 0) {
                shoudSendExpressionData = true;
            }
        })
    }

    // Video Recording
    const videoStreamRef = React.useRef<any>();
    const download_link = React.useRef<any>();
    const [stream, setStream] = React.useState({
        access: false,
        recorder: null,
        error: ""
    });
    const [recording, setRecording] = React.useState({
        active: false,
        available: false,
        url: ""
    });
    const chunks = React.useRef<any>([]);

    React.useEffect(() => {
        if (questionId > 1) {
            setShowGoToTailReport(true);
        }
        if (questionId <= 3) {
            localStorage.setItem("questionId", questionId.toString());
            fetchQuestionWithVideo();
        }
    }, [questionId]);
    React.useEffect(() => {
        videoRef?.current?.load();
    }, [currentVideo]);

    // React.useEffect(() => {
    //     if (faceDetectionCount > 3) {
    //         videoRef.current.pause();
    //         // clearInterval(timerIntervalId);
    //     } else {
    //         if (videoRef && videoRef.current) {
    //             videoRef.current.play();
    //         }
    //     }
    // }, [faceDetectionCount]);

    const fetchQuestionWithVideo = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        };
        const questionId = localStorage.getItem("questionId");
        const result = await fetch(`${service_url}/api/question/${questionId}/${selectedlangauage}`, requestOptions);
        const data = await result.json();
        if (data.statusCode === 200) {
            setCurrentVideo(data.data[0].video_link);
            SetCatName(data.data[0].catName)
            // startStream()
            // startFaceApi();

            setQnASession(false)
            videoFaceApiRef && loadModels()
            setQnAData({ id: data.data[0].id, question: data.data[0].questions, option1: data.data[0].option1, option2: data.data[0].option2, option3: data.data[0].option3, option4: data.data[0].option4 });
        } else {
            setErrMsg(data.statusCode + "-" + data.message)
        }
    }
    // const playVideo = () => {
    //     if (videoRef && videoRef.current) {
    //         videoRef.current.play();
    //         setPlayClicked(true);
    //     }
    // }
    const submitAnswer = (selectedOption: string) => {
        if (selectedOption) {
            if (questionId === 1) {
                console.log('Ans1 submitted' + selectedOption)
                setAnswerApiBody((prev: any) => {
                    return { ...prev, answer1: selectedOption }
                })
            }
            else if (questionId === 2) {
                console.log('Ans2 submitted' + selectedOption)
                setAnswerApiBody((prev: any) => {
                    console.log(prev)

                    return { ...prev, answer2: selectedOption }
                })
            }
            else if (questionId === 3) {
                clearInterval(timerIntervalId);
                console.log('Ans3 submitted' + selectedOption)
                setAnswerApiBody((prev: any) => {
                    answer3 = { ...prev, answer3: selectedOption }
                    return { ...prev, answer3: selectedOption }
                })
                console.log(answer3)
                sendAnswer()
            }
            const newQuestionId = questionId + 1;
            setQuestionId(newQuestionId);
            answerClick({'answers':`${getCategogryNameById(questionId)}-${selectedOption}`})
        }
    }

    const sendAnswer = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(answer3)
        };
        const result = await fetch(`${service_url}/api/answers`, requestOptions);
        const data = await result.json();
        videoFaceApiRef.current.srcObject.getTracks()[0].stop()
        buildReport();
        // naviagte('/infograph')
        if (timerIntervalId) {
            clearInterval(timerIntervalId);
        }
        if (videoRef?.current) {
            videoRef.current.pause();
            try {
                videoRef.current.srcObject.getTracks()[0].stop();
            } catch (e) {

            }
        }
        naviagte('/load')
    }

    // Build Report 

    const buildReport = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ token_id: localStorage.getItem('token_id') })
        };
        const result = await fetch(`${service_url}/api/scores/buildReport`, requestOptions);
        const data = await result.json();
        if (data.data) {
            localStorage.setItem('profileId', data.data.profileId)
            buttonClick(`Highest Sparked-${getSparkedTextByProfileId(data.data.profileId)}`);
        }
    }

    const handleOnEnded = () => {
        setQnASession(true);
        setQnaBackground();
        videoFaceApiRef.current.srcObject.getTracks()[0].stop();
    }


    const onTimeUpdate = (e: any) => {
        const progress = Math.round(
            (videoRef?.current?.currentTime / videoRef?.current?.duration) * 100
        );
        if (questionId == 1) {
            setComplted1(progress);
        } else if (questionId == 2) {
            setComplted2(progress);
        } else if (questionId == 3) {
            setComplted3(progress);
        }
    }

    const handleRiskReportBtn = () => {
        window.open("https://www.pgim.com/global-tail-risks", "_self");
    }

    return (
        <div className={styles.videoEmotionContainer}>
        <div className={styles.videoEmotion} id='video-emotion'>
            {currentVideo && <video controls={false} id="myVideo1" className={styles.myVideo1} ref={videoRef} crossOrigin="anonymous" onEnded={handleOnEnded} onTimeUpdate={onTimeUpdate} preload="metadata" autoPlay playsInline>
                <source src={currentVideo} type="video/mp4" />
                {/* <track label="English" kind='subtitles' srcLang='en' src={enSubtitle} default /> */}
            </video>
            }
            {/* {errMsg && <p style={{ color: '#fff' }}>{errMsg}</p>} */}
            {
                QnASession && (
                    <div className={styles.qA_container} style={{ backgroundImage: backgroudImage }}>
                        {/* <h1 className={styles.catname}>{catName}</h1> */}
                        <Typography variant="h3" component="h4" className={styles.question}>
                            {QnAData.question}
                        </Typography>
                        <div className={styles.answer_container}>
                            <Button variant="contained" className={styles.button} onClick={() => submitAnswer(QnAData.option1)}>{QnAData.option1}</Button>
                            <Button variant="contained" className={styles.button} onClick={() => submitAnswer(QnAData.option2)}>{QnAData.option2}</Button>
                            <Button variant="contained" className={styles.button} onClick={() => submitAnswer(QnAData.option3)}>{QnAData.option3}</Button>
                            <Button variant="contained" className={styles.button} onClick={() => submitAnswer(QnAData.option4)}>{QnAData.option4}</Button>
                        </div>
                    </div>
                )
            }
            {!QnASession && showGoToTailReport && (
                <Typography variant="h6" component="h6" onClick={handleRiskReportBtn} className={styles.riskReportBtn} id="skipToReport">
                    Access the tail risk report&gt;
                </Typography>
            )}

            <video id="videoFaceApi" width={120} className={styles.videoFaceApi} ref={videoFaceApiRef} autoPlay muted playsInline></video>
            {/* {!playClicked && showUserConsent && <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={styles.question_dialog}
            >
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" className={styles.button} onClick={() => playVideo()}>READY TO START</Button>
                </DialogActions>
            </Dialog>
            } */}
            <div className={styles.pb_container}>
                <ProgressBar completed={completed1} bgColor="#5266FF" borderRadius="20px" height="15px" customLabel=" " />
                <ProgressBar completed={completed2} bgColor="#5266FF" borderRadius="20px" height="15px" customLabel=" " />
                <ProgressBar completed={completed3} bgColor="#5266FF" borderRadius="20px" height="15px" customLabel=" " />
            </div>
        </div>
        </div>
    )
}

export default VideoEmotion
