import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import * as faceapi from "face-api.js";
import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import imgMesh from "../../Assets/images/face_mesh.png";
import styles from "./FaceDetect.module.scss";

type Props = {
  setCurrentStage: (currentStage: number) => void;
}

const FaceDetect: FC<Props> = ({ setCurrentStage }) => {

  const navigate = useNavigate();
  const [faceMatchCount, setfaceMatchCount] = React.useState(0);
  const [noFaceFoundCount, setNoFaceFoundCount] = React.useState(0);
  // const [detectMode, setDetectMode] = React.useState(false);
  const [faceFound, setfaceFound] = React.useState(false);
  // const [showAnimScan, setShowAnimScan] = React.useState(false);
  const videoRef = React.useRef<any>();
  const imgRef = React.useRef<any>();
  const faceDetectDiv = React.useRef<any>();
  const [detectionId, setDetectionId] = React.useState<any>();
  const {t} = useTranslation();
  
  React.useEffect(() => {
    handleDetectForFaceApi()
  }, [])

  React.useEffect(() => {
    if (faceMatchCount > 9) {// We are making user to stay before camera for atleast 3 seconds
      clearInterval(detectionId);
      setfaceFound(true);
      setTimeout(() => {
        // navigate('/video-emotion');
        if (videoRef?.current) {
          videoRef.current.pause();
          videoRef.current.srcObject.getTracks()[0].stop()
        }
        setCurrentStage(3); //Stage 3 means Video Emotion screen
      }, 2500)
    }
    return () => {
    }
  }, [faceMatchCount])

  React.useEffect(() => {
    // If no face count is greater than 60, show alert and redirect to home
    if (noFaceFoundCount > 60) {
      setfaceMatchCount(0);
      clearInterval(detectionId);
      if (videoRef?.current) {
        videoRef.current.pause();
        videoRef.current.srcObject.getTracks()[0].stop()
      }
      // navigate("/", { state: { "from": "faceDetectAlert" } })
    }
  }, [noFaceFoundCount])

  const handleDetectForFaceApi = () => {
    // setDetectMode(true);
    // setShowAnimScan(true);
    // startVideo();
    videoRef && loadModels()

  }

  const startVideo = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((currentStream) => {
        imgRef.current.src = imgMesh;
        videoRef.current.srcObject = currentStream;
        detectFace(videoRef.current)
      })
      .catch((err) => {
        console.error(err)
        navigate("/")
      });
  }

  const detectFace = (video: any) => {
    video.addEventListener('play', async () => {
      setDetectionId(setInterval(async () => {
        const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks();
        if (detections && detections.length > 0) {
          setfaceMatchCount(faceMatchCount => faceMatchCount + 1);
        } else {
          setfaceMatchCount(0);
          setNoFaceFoundCount(noFaceFoundCount => noFaceFoundCount + 1)
        }
      }, 100));
    })
  }

  const loadModels = () => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
      // faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
      // faceapi.nets.faceExpressionNet.loadFromUri('/models'),
    ]).then(startVideo)
  }

  return (
    <>
      <Box className={styles.face_Container}>

        <Box sx={{ flexGrow: 1 }} className={styles.faceDetectArea}>

          <Grid container className={styles.gc}>


            <Grid item xs={12}>

              <div className={styles.face_detect} ref={faceDetectDiv}>
                {/* {!detectMode && <img className={styles.face_gif} src={require('../../Assets/images/gifs/facescan1.gif')} alt="faceGif" />} */}
                {<video id="video" width="360" height="280" className={styles.video} ref={videoRef} autoPlay muted playsInline></video>}
                <img ref={imgRef} src="" style={{ display: 'none' }} />
              </div>

              <Box className={styles.buttondiv}>
                {/* {!detectMode && <Button variant="contained" className={styles.button} onClick={() => handleDetectForFaceApi()}>SET UP FACE TRACKING</Button>} */}
                {<div className={styles.face_found_text} style={{}}>
                  {!faceFound && (<Typography variant="h6" component="h6" sx={{ fontFamily: "inherit", letterSpacing: "1px", fontSize: '1.7rem', textTransform: 'capitalize' }}>
                  {t("faceDetect_page.text1")}
                    <div className={styles.loading}>
                      <div className={styles.dots}></div>
                      <div className={styles.dots}></div>
                      <div className={styles.dots}></div>
                    </div>
                  </Typography>)}
                  {faceFound && <Typography variant="h6" component="h6" sx={{ fontFamily: "inherit", letterSpacing: "1px", fontSize: '1.5rem', margin: "-10px" }}>
                  {t("faceDetect_page.text2")}
                  </Typography>
                  }
                  <div className="face_found_text" style={{ textAlign: 'center', color: '#fff', marginTop: '15px' }}>
                  {!faceFound && <Typography variant="h6" component="h6" sx={{ fontFamily: "inherit", letterSpacing: "1px", fontSize: '1.1rem',lineHeight:'1.3' }}>{t("faceDetect_page.text6")}</Typography>}
                  {!faceFound && <Typography variant="h6" component="h6" sx={{ fontFamily: "inherit", letterSpacing: "1px", fontSize: '1.1rem',lineHeight:'1.3' }}>{t("faceDetect_page.text5")}</Typography>}
                  {/* {faceFound && <Typography variant="h6" component="h6" sx={{ fontFamily: "inherit", letterSpacing: "1px", fontSize: '1rem' }}>{t("faceDetect_page.text3")}</Typography>} */}
                  </div>
                </div>

                }

              </Box>

            </Grid>
          </Grid>

        </Box>

      </Box>
    </>
  )
}

export default FaceDetect