import { Route, Routes } from 'react-router-dom'
import Loader from '../Components/Loader/Loader'
import Demo from '../Pages/InfographicReport/Demo'
import Demo2 from '../Pages/InfographicReport/Demo2'
import Demo3 from '../Pages/InfographicReport/Demo3'
import Demo4 from '../Pages/InfographicReport/Demo4'
import Graph from '../Pages/InfographicReport/Graph'
import Infograph from '../Pages/InfographicReport/InfographReport'
import EmotionAnalysisContainer from '../Pages/Main/EmotionAnalysisContainer'
import Start from '../Pages/Start/Start'
import VideoEmotion from '../Pages/VideoEmotion/VideoEmotion'
import VideoTest from '../Pages/videoTest/videoTest'

const Rout = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Start />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/face-detect" element={<FaceDetect />} />
        <Route path="/video-intro" element={<VideoIntro />} /> */}
        <Route path="/video-emotion" element={<VideoEmotion />} />
        <Route path="/video-test" element={<VideoTest />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/demo2" element={<Demo2 />} />
        <Route path="/demo3" element={<Demo3 />} />
        <Route path="/demo4" element={<Demo4 />} />
        <Route path="/demo5" element={<Infograph />} />
        <Route path="/load" element={<Loader />} />
        <Route path="/infograph" element={<Graph />} />
        <Route path="/emotion-analysis" element={<EmotionAnalysisContainer />} />
      </Routes>

    </>

  )
}

export default Rout