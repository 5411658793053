import { Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../Components/Logo/Logo";
import styles from "./About.module.scss";
import LogoTxt from "../../Assets/images/logoTxt.png";
import { useTranslation } from "react-i18next";
import { buttonClick } from "../../Utils/GtagEvents";

type Props = {
  setCurrentStage: (currentStage: number) => void;
}

const About: FC<Props> = ({ setCurrentStage }) => {

  const navigate = useNavigate();
  const {t} = useTranslation();

  const continueBtnClick =  ()=>{
    setCurrentStage(2);
    buttonClick("CONTINUE");
  }
  
  return (
    <>
      <Box className={styles.about_Container}>
        <img className={styles.face_gif} src={require('../../Assets/images/gifs/facescan1.gif')} alt="faceGif" />
        <Box sx={{ flexGrow: 1 }} className={styles.aboutArea}>

          <Grid container className={styles.gc}>
            <div className={styles.logoO}>
              {/* <Logo /> */}
            </div>
            <img src={LogoTxt} alt="logo-PGIM" className={styles.newLogo} />
            <Grid className={styles.about} item xs={12}>
              <Typography variant="h6" component="h6" className={styles.about_heading}>
              {t("about_page.heading1")}
              </Typography>
              <Typography variant="h6" component="h6" className={styles.about_heading}>
              {t("about_page.heading2")}
              </Typography>
              <Typography variant="h6" component="h6" className={styles.about_heading}>
              {t("about_page.heading3")}
              </Typography>
              <Typography variant="h6" component="h6" className={styles.about_textPara}>
              {t("about_page.subHeading1")}
              </Typography>

              <Typography variant="h6" component="h6" className={styles.about_textPara}>
              {t("about_page.subHeading2")}
              </Typography>

              <Typography variant="h6" component="h6" className={styles.about_textPara}>
              {t("about_page.subHeading3")}
              </Typography>


              <Box className={styles.buttondiv}>
                {/* Current stage 2 means it will take user to Video Detect Screen */}
                <Button variant="contained" sx={{ padding: 0 }} className={styles.button} onClick={()=>continueBtnClick()}>{t("about_page.buttonText")}</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default About;
