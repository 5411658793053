import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react'
import styles from "./FaceNotFoundDialog.module.scss";
import faceNotFoundIcon from "../../Assets/images/face_not_found_icon.jpg";

export interface FaceNotFoundDialogProps {
  id?: string;
  keepMounted?: boolean;
  open: boolean;
  onClose: (event?: object, reason?: string) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide
      direction="left"
      mountOnEnter
      unmountOnExit
      easing={{
        enter: "cubic-bezier(.17,.67,.83,.67)",
        exit: "cubic-bezier(.17,.67,.83,.67)",
      }}
      ref={ref}
      {...props}
    />
  );
});

function FaceNotFoundDialog({ id, open, onClose }: FaceNotFoundDialogProps) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={(event: object, reason: string) => onClose(event, reason)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles.faceNotFoundDialog}
      disableEscapeKeyDown={true}
    >
      <DialogTitle className={styles.title}  >
        Face not detected
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: "0" }}>
        <DialogContentText
          className={styles.dialogContent}
          id="alert-dialog-description"
        >
          <img src={faceNotFoundIcon} alt="faceNotFoundIcon" className={styles.icon}/>
          <p className={styles.text1}>Check the camera permissions</p>
          <p className={styles.text2}>Redirecting to Home</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.action}>
        <Button onClick={() => onClose()} className={styles.actionBtn}>
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FaceNotFoundDialog